import { call, put, select, takeLatest } from 'redux-saga/effects';
import { GamesTypes } from './games-types';
import { GamesAction } from './games-action';

const fetchStart = function* (action) {
	const filters = yield select(store => store.GamesReducer.filters);
	const fetchTask = () => new Promise((resolve, reject) => {
		const url = Object.keys(filters).filter(key => filters[key] !== null).reduce((accumulator, current) => (accumulator + `&${current}=${filters[current]}`), `/api/games?offset=${action.payload.offset}`);
		fetch(url).then(response => response.json()).then((json) => {
			resolve(json);
		}).catch((err) => {
			reject(err);
		});
	});
	const games = yield call(fetchTask);
	yield put(GamesAction.fetchComplete(action.payload.offset, games));
};

const fetchMetaStart = function* () {
	const fetchStatsTask = () => new Promise((resolve, reject) => {
		fetch("/api/stats").then(response => response.json()).then((json) => {
			resolve(json);
		}).catch((err) => {
			reject(err);
		});
	});
	const fetchTagsTask = () => new Promise((resolve, reject) => {
		fetch("/api/taglist").then(response => response.json()).then((json) => {
			resolve(json);
		}).catch((err) => {
			reject(err);
		});
	});
	const fetchGenresTask = () => new Promise((resolve, reject) => {
		fetch("/api/genrelist").then(response => response.json()).then((json) => {
			resolve(json);
		}).catch((err) => {
			reject(err);
		});
	});
	const stats = yield call(fetchStatsTask);
	const tags = yield call(fetchTagsTask);
	const genres = yield call(fetchGenresTask);
	yield put(GamesAction.fetchMetaComplete({
		stats: {
			count: stats["count"],
			priceMax: stats["price_max"],
			priceAvg: stats["price_avg"],
			priceMedian: stats["price_median"]
		},
		tags,
		genres
	}));
};

export const GamesSaga = function* () {
	yield takeLatest(GamesTypes.FETCH_START, fetchStart);
	yield takeLatest(GamesTypes.FETCH_META_START, fetchMetaStart);
};
