export const styles = {
	img: {
		width: '120px',
		height: '45px'
	},
	modal: {
		width: '1438px',
		height: '810px'
	},
	margin: {
		marginTop: '8px',
		marginBottom: '8px'
	}
};
