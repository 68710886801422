import React from 'react';
import { Layout } from 'antd';
import { styles } from './styles';

const { Footer } = Layout;

export const FooterComponent = () => (
	<Footer style={styles.footer}>
		Project ©2020 Created by ToInfinityAndBeyond
	</Footer>
);
