import { FavoritesTypes } from "./favorites-types";

const fetchStart = () => ({
	type: FavoritesTypes.FETCH_START
});

const fetchComplete = (favorites) => ({
	type: FavoritesTypes.FETCH_COMPLETE,
	payload: {
		favorites: favorites.map(game => parseInt(new RegExp('(\\d+)\\/header\\.jpg').exec(game.header_image)[1]))
	}
});

const set = appid => ({
	type: FavoritesTypes.SET,
	payload: {
		appid
	}
});

const unset = appid => ({
	type: FavoritesTypes.UNSET,
	payload: {
		appid
	}
});

export const FavoritesAction = {
	fetchStart,
	fetchComplete,
	set,
	unset
};
