import { call, put, takeLatest } from 'redux-saga/effects';
import { AuthTypes } from './auth-types';
import { AuthAction } from './auth-action';

const status = function* (action) {
	const statusTask = () => new Promise((resolve, reject) => {
		fetch('/api/status').then(response => response.json()).then((json) => {
			resolve(json);
		}).catch((err) => {
			reject(err);
		});
	});
	try {
		const credentials = yield call(statusTask);
		if (credentials.userid !== undefined && credentials.email !== undefined) {
			yield put(AuthAction.loginSuccess(credentials.userid, credentials.email));
		}
	} catch (err) {
		console.error(err);
	}
};

const signupStart = function* (action) {
	const signupTask = () => new Promise((resolve, reject) => {
		fetch('/api/signup', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				email: action.payload.email,
				password: action.payload.password
			})
		}).then(response => response.json()).then((json) => {
			resolve(json);
		}).catch((err) => {
			reject(err);
		});
	});
	try {
		const credentials = yield call(signupTask);
		if (credentials.userid !== undefined && credentials.email !== undefined) {
			yield put(AuthAction.loginSuccess(credentials.userid, credentials.email));
		} else {
			yield put(AuthAction.error('Incorrect email or password.'));
		}
	} catch (err) {
		console.error(err);
	}
};

const loginStart = function* (action) {
	const loginTask = () => new Promise((resolve, reject) => {
		fetch('/api/signin', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: action.payload.email,
				password: action.payload.password
			})
		}).then(response => response.json()).then((json) => {
			resolve(json);
		}).catch((err) => {
			reject(err);
		});
	});
	try {
		const credentials = yield call(loginTask);
		if (credentials.userid !== undefined && credentials.email !== undefined) {
			yield put(AuthAction.loginSuccess(credentials.userid, credentials.email));
		} else {
			yield put(AuthAction.error('Invalid email or password.'));
		}
	} catch (err) {
		console.error(err);
	}
};

const logout = function* () {
	document.cookie = 'sessionid=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const AuthSaga = function* () {
	yield takeLatest(AuthTypes.STATUS, status);
	yield takeLatest(AuthTypes.SIGNUP_START, signupStart);
	yield takeLatest(AuthTypes.LOGIN_START, loginStart);
	yield takeLatest(AuthTypes.LOGOUT, logout);
};
