import { AuthTypes } from './auth-types';

const status = () => ({
	type: AuthTypes.STATUS
});

const signupStart = (email, password) => ({
	type: AuthTypes.SIGNUP_START,
	payload: {
		email,
		password
	}
});

const loginStart = (email, password) => ({
	type: AuthTypes.LOGIN_START,
	payload: {
		email,
		password
	}
});

const loginSuccess = (userid, email) => ({
	type: AuthTypes.LOGIN_SUCCESS,
	payload: {
		userid,
		email
	}
});

const error = (error) => ({
	type: AuthTypes.ERROR,
	payload: {
		error
	}
});

const logout = () => ({
	type: AuthTypes.LOGOUT
});

export const AuthAction = {
	status,
	signupStart,
	loginStart,
	loginSuccess,
	logout,
	error
};
