export const styles = {
	layout: {
		minHeight: '100vh'
	},
	header: {
		color: 'white'
	},
	carousal: {
		margin: '16px',
		padding: '8px',
		background: 'white'
	}
};
