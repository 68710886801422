import React from 'react';
import { Layout } from 'antd';
import { SiderComponent } from '../sider/sider-component';
import { HeaderComponent } from '../header/header-component';
import { FooterComponent } from '../footer/footer-component';
import {styles} from './styles';

const { Content } = Layout;

export const LayoutComponent = (props) => (
	<React.Fragment>
		<Layout style={styles.layout}>
			<SiderComponent/>
			<Layout>
				<HeaderComponent/>
				<Content style={styles.content}>
					{props.children}
				</Content>
				<FooterComponent/>
			</Layout>
		</Layout>
	</React.Fragment>
);
