export const styles = {
	container: {
		justifyContent: "center"
	},
	header: {
		color: "white",
		height: "100%",
		width: "100%",
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center"
	},
	buttonSubmit: {
		float: "right"
	},
	formHeader: {
		backgroundColor: "white"
	},
	formContent: {
		backgroundColor: "white"
	},
	error: {
		color: 'red'
	}
};
