export const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: '100vh'
	},
	content: {
		flex: 1,
		backgroundColor: '#1b2838'
	},
	carousal: {
		height: '810px'
	},
	carousalItem: {
		height: '810px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	card: {
		width: '460px',
	},
	browse: {
		flex: 1,
		display: 'flex',
		justifyContent: 'flex-end'
	}
};
