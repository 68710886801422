import { createReducer } from '../utils';
import { AuthTypes } from './auth-types';
import { produce } from 'immer';

const initialState = {
	userid: null,
	email: null,
	error: null
};

const loginSuccess = (state, payload) => produce(state, (draftState) => {
	draftState.userid = payload.userid;
	draftState.email = payload.email;
	draftState.error = null;
});

const logout = () => initialState;

const error = (state, payload) => produce(state, (draftState) => {
	draftState.error = payload.error;
});

export const AuthReducer = createReducer(initialState, {
	[AuthTypes.LOGIN_SUCCESS]: loginSuccess,
	[AuthTypes.LOGOUT]: logout,
	[AuthTypes.ERROR]: error
});
