import { applyMiddleware, combineReducers, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { all, call, fork } from "redux-saga/effects";
import { LoggerReducer } from "./logger/logger-reducer";
import { LoggerSaga } from "./logger/logger-saga";
import { GamesReducer } from "./games/games-reducer";
import { GamesSaga } from "./games/games-saga";
import { FavoritesReducer } from "./favorites/favorites-reducer";
import { FavoritesSaga } from "./favorites/favorites-saga";
import { RecommendationsReducer } from "./recommendations/recommendations-reducer";
import { RecommendationsSaga } from "./recommendations/recommendations-saga";
import { AuthReducer } from "./auth/auth-reducer";
import { AuthSaga } from "./auth/auth-saga";

const saga = createSagaMiddleware();

export const Store = createStore(combineReducers({
	LoggerReducer,
	GamesReducer,
	FavoritesReducer,
	RecommendationsReducer,
	AuthReducer
}), applyMiddleware(saga));

saga.run(function* () {
	yield all([
		call(LoggerSaga),
		...[
			GamesSaga,
			FavoritesSaga,
			RecommendationsSaga,
			AuthSaga
		].map(fork)
	]);
});
