import React from "react";
import { connect } from "react-redux";
import { Button, Collapse, Form, Pagination, Rate, Select, Slider, Table, Typography, Input } from "antd";
import { LayoutComponent } from "../../components/layout/layout-component";
import { GamesAction } from "../../redux/games/games-action";
import { FavoritesAction } from "../../redux/favorites/favorites-action";
import { styles } from "./styles";
import moment from "moment";

const { Title } = Typography;
const { Panel } = Collapse;
const { Item } = Form;
const {Option} = Select;

class Component extends React.Component {

	constructor(props) {
		super(props);
		this.handlePagination = this.handlePagination.bind(this);
		this.handleFavoriteClick = this.handleFavoriteClick.bind(this);
		this.handlePriceFilter = this.handlePriceFilter.bind(this);
		this.handleDescriptionFilter = this.handleDescriptionFilter.bind(this);
		this.handleTagFilter = this.handleTagFilter.bind(this);
		this.handleGenreFilter = this.handleGenreFilter.bind(this);
		this.handlePlatformFilter = this.handlePlatformFilter.bind(this);
	}

	render() {
		const priceMaxRounded = Math.ceil(this.props.GamesReducer.stats.priceMax ? this.props.GamesReducer.stats.priceMax : this.props.GamesReducer.filters.price);
		const filters = (
			<Collapse>
				<Panel key="filter" header="Filters">
					<Form>
						<Item label="Price">
							<Slider defaultValue={priceMaxRounded} max={priceMaxRounded} onAfterChange={this.handlePriceFilter}/>
						</Item>
						<Item label="Description">
							<Input onChange={e => this.handleDescriptionFilter(e.target.value)} value={this.props.GamesReducer.filters.description} />
							<Button onClick={() => this.handleDescriptionFilter(null)}>
								clear
							</Button>
						</Item>
						<Item label="Tag">
							<Select showSearch onChange={this.handleTagFilter} value={this.props.GamesReducer.filters.tag}>
								{
									Object.keys(this.props.GamesReducer.tags).map(tag => (
										<Option key={tag}>
											{tag}
										</Option>
									))
								}
							</Select>
							<Button onClick={() => this.handleTagFilter(null)}>
								clear
							</Button>
						</Item>
						<Item label="Genre">
							<Select showSearch onChange={this.handleGenreFilter}  value={this.props.GamesReducer.filters.genre}>
								{
									Object.keys(this.props.GamesReducer.genres).map(tag => (
										<Option key={tag}>
											{tag}
										</Option>
									))
								}
							</Select>
							<Button onClick={() => this.handleGenreFilter(null)}>
								clear
							</Button>
						</Item>
						<Item label="Platform">
							<Select showSearch onChange={this.handlePlatformFilter}  value={this.props.GamesReducer.filters.platform}>
								{
									[
										"mac",
										"windows",
										"linux"
									].map(tag => (
										<Option key={tag}>
											{tag}
										</Option>
									))
								}
							</Select>
							<Button onClick={() => this.handlePlatformFilter(null)}>
								clear
							</Button>
						</Item>
					</Form>
				</Panel>
			</Collapse>
		);
		const pagination = (
			<Pagination
				defaultCurrent={1}
				current={this.props.GamesReducer.offset / 100 + 1}
				pageSize={100}
				showSizeChanger={false}
				total={Math.max(...Object.keys(this.props.GamesReducer.games).map(key => parseInt(key))) + 101}
				onChange={this.handlePagination}
			/>
		);
		const columns = (() => {
			const defaults = [
				{
					title: "Game",
					dataIndex: "name",
					key: "name",
					render: (text, record) => (
						<React.Fragment>
							<img src={record.header_image} style={styles.img} alt='banner'/>{text}
						</React.Fragment>
					)
				},
				{
					title: "Publisher",
					dataIndex: "publisher",
					key: "publisher"
				},
				{
					title: "Release Date",
					dataIndex: "release_date",
					key: "release_date",
					render: text => moment(text, "MM-DD-YY").format("Do MMM, YYYY")
				},
				{
					title: "Price",
					dataIndex: "price",
					key: "price",
					render: text => "$" + text
				},
				{
					title: "Owners",
					dataIndex: "owners",
					key: "owners"
				},
				{
					title: "Description",
					dataIndex: "short_description",
					key: "short_description"
				}
			];
			const favorites = {
				title: "",
				key: "favorite",
				render: (text, record) => (
					<React.Fragment>
						<Rate
							count={1}
							value={(this.props.FavoritesReducer.favorites.includes(record.appid) ? 1 : 0)}
							onChange={this.handleFavoriteClick(record)}/>
					</React.Fragment>
				)
			};
			if (!this.props.AuthReducer.userid)
				return defaults;
			return [
				favorites,
				...defaults
			];
		})();
		return (
			<React.Fragment>
				<LayoutComponent>
					<Title level={2}>
						Browse Games
					</Title>
					<div style={styles.margin}>
						{filters}
					</div>
					{pagination}
					<Table
						rowKey='appid'
						columns={columns}
						dataSource={this.props.GamesReducer.games[this.props.GamesReducer.offset]}
						loading={this.props.GamesReducer.loading}
						pagination={false}
					/>
					{pagination}
				</LayoutComponent>
			</React.Fragment>
		);
	}

	componentDidMount() {
		this.props.dispatch(GamesAction.fetchStart(0));
		this.props.dispatch(GamesAction.fetchMetaStart());
		this.props.dispatch(FavoritesAction.fetchStart());
	}


	handlePagination(page) {
		this.props.dispatch(GamesAction.fetchStart(100 * (page - 1), this.props.GamesReducer.filters.price));
	}

	handleFavoriteClick(record) {
		return (number) => {
			if (number === 0) {
				this.props.dispatch(FavoritesAction.unset(record.appid));
			} else {
				this.props.dispatch(FavoritesAction.set(record.appid));
			}
		};
	}

	handlePriceFilter(price) {
		if (price !== this.props.GamesReducer.filters.price) {
			this.props.dispatch(GamesAction.filter({
				price
			}));
			this.props.dispatch(GamesAction.fetchStart(0));
		}
	}

	handleDescriptionFilter(description) {
		this.props.dispatch(GamesAction.filter({
			description
		}));
		this.props.dispatch(GamesAction.fetchStart(0));
	}

	handleTagFilter(tag) {
		this.props.dispatch(GamesAction.filter({
			tag
		}));
		this.props.dispatch(GamesAction.fetchStart(0));
	}

	handleGenreFilter(genre) {
		this.props.dispatch(GamesAction.filter({
			genre
		}));
		this.props.dispatch(GamesAction.fetchStart(0));
	}

	handlePlatformFilter(platform) {
		this.props.dispatch(GamesAction.filter({
			platform
		}));
		this.props.dispatch(GamesAction.fetchStart(0));
	}

}

const Redux = connect(store => ({
	AuthReducer: store.AuthReducer,
	GamesReducer: store.GamesReducer,
	FavoritesReducer: store.FavoritesReducer
}))(Component);

export const GamesPage = Redux;
