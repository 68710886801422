import React from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { FileSearchOutlined, HomeOutlined } from '@ant-design/icons';
import { styles } from './styles';

const { Sider } = Layout;

const urlMapper = (pathname) => ({
	'/': 'home',
	'/games': 'games'
})[pathname];

class Component extends React.Component {

	constructor(props) {
		super(props);
		this.handleRedirect = this.handleRedirect.bind(this);
	}

	render() {
		return (
			<React.Fragment>
				<Sider>
					<div style={styles.logo}/>
					<Menu theme="dark" mode="inline" selectedKeys={urlMapper(this.props.location.pathname)}>
						<Menu.Item key="home" onClick={this.handleRedirect('/')}>
							<HomeOutlined/>
							<span>
								Home
							</span>
						</Menu.Item>
						<Menu.Item key="games" onClick={this.handleRedirect('/games')}>
							<FileSearchOutlined/>
							<span>
								Browse Games
							</span>
						</Menu.Item>
					</Menu>
				</Sider>
			</React.Fragment>
		);
	}

	handleRedirect(url) {
		return () => {
			if (this.props.location.pathname !== url) {
				this.props.history.push(url);
			}
		};
	}

}

const Router = withRouter(Component);

export const SiderComponent = Router;
