import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, Carousel } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { HeaderComponent } from "../../components/header/header-component";
import { FooterComponent } from "../../components/footer/footer-component";
import { styles } from "./styles";
import "./index-page.css";
import { RecommendationsAction } from "../../redux/recommendations/recommendations-action";

class Component extends React.Component {

	constructor(props) {
		super(props);
		this.handleRedirect = this.handleRedirect.bind(this);
	}

	render() {
		return (
			<React.Fragment>
				<div style={styles.container}>
					<HeaderComponent/>
					<div style={styles.content}>
						<div style={styles.carousal}>
							<Carousel autoplay>
								{
									this.props.RecommendationsReducer.recommendations.map((game) => (
										<div key={game.appid}>
											<div
												style={{
													...styles.carousalItem,
													backgroundImage: `url(${game.background})`
												}}
											>
												<Card style={styles.card} cover={<img src={game.header_image}/>}>
													<Card.Meta title={game.name}/>
												</Card>
											</div>
										</div>
									))
								}
								<div>
									<Button type="dashed" icon={<SearchOutlined/>} onClick={this.handleRedirect("/games")}>
										Browse Games
									</Button>
								</div>
							</Carousel>
						</div>
					</div>
					<FooterComponent/>
				</div>
			</React.Fragment>
		);
	}

	componentDidMount() {
		this.props.dispatch(RecommendationsAction.fetchStart());
	}

	handleRedirect(url) {
		return () => {
			this.props.history.push(url);
		};
	}

}

const Redux = connect(store => ({
	RecommendationsReducer: store.RecommendationsReducer
}))(Component);

const Router = withRouter(Redux);

export const IndexPage = Router;
