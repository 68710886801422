import { createReducer } from "../utils";
import { FavoritesTypes } from "./favorites-types";
import { produce } from "immer";

const initialState = {
	favorites: []
};

const fetchComplete = (state, payload) => produce(state, draftState => {
	draftState.favorites = payload.favorites;
});

export const FavoritesReducer = createReducer(initialState, {
	[FavoritesTypes.FETCH_COMPLETE]: fetchComplete
});
