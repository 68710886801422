import { createReducer } from "../utils";
import { RecommendationsTypes } from "./recommendations-types";
import { produce } from "immer";

const initialState = {
	recommendations: []
};

const fetchComplete = (state, payload) => produce(state, draftState => {
	draftState.recommendations = payload.recommendations;
});

export const RecommendationsReducer = createReducer(initialState, {
	[RecommendationsTypes.FETCH_COMPLETE]: fetchComplete
});
