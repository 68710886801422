import { createReducer } from "../utils";
import { GamesTypes } from "./games-types";
import { produce } from "immer";

const initialState = {
	loading: false,
	games: {},
	stats: {},
	tags: {},
	genres: {},
	offset: 0,
	filters: {
		price: null,
		description: null,
		tag: null,
		genre: null,
		platform: null
	},
	focus: null
};

const filter = (state, payload) => produce(state, draftState => {
	draftState.filters = {
		...state.filters,
		...payload.filter
	};
	draftState.loading = true;
});

const fetchStart = (state, payload) => produce(state, draftState => {
	if (!state.games[payload.offset])
		draftState.loading = true;
	draftState.offset = payload.offset;
});

const fetchComplete = (state, payload) => produce(state, draftState => {
	draftState.loading = false;
	if (!state.games[payload.offset]) {
		draftState.games[payload.offset] = payload.data;
	} else {
		if (JSON.stringify(state.games[payload.offset]) !== JSON.stringify(payload.data)) {
			draftState.games = {
				[payload.offset]: payload.data
			};
		}
	}
});

const fetchMetaComplete = (state, payload) => produce(state, draftState => {
	draftState.stats = payload.stats;
	draftState.tags = payload.tags;
	draftState.genres = payload.genres;
});

export const GamesReducer = createReducer(initialState, {
	[GamesTypes.FILTER]: filter,
	[GamesTypes.FETCH_START]: fetchStart,
	[GamesTypes.FETCH_COMPLETE]: fetchComplete,
	[GamesTypes.FETCH_META_COMPLETE]: fetchMetaComplete
});
