import {RecommendationsTypes} from "./recommendations-types";

const fetchStart = () => ({
	type: RecommendationsTypes.FETCH_START
});

const fetchComplete = recommendations => ({
	type: RecommendationsTypes.FETCH_COMPLETE,
	payload: {
		recommendations: recommendations.map(game => ({
			appid: (() => {
				try {
					return parseInt(new RegExp('(\\d+)\\/header').exec(game.header_image)[1]);
				} catch (err) {
					console.error(`Failed to parse appid for ${game.name}`)
				}
			})(),
			...game
		}))
	}
});

export const RecommendationsAction = {
	fetchStart,
	fetchComplete
};
