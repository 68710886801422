import { GamesTypes } from './games-types';

const filter = filter => ({
	type: GamesTypes.FILTER,
	payload: {
		filter
	}
});

const fetchStart = (offset = 0) => ({
	type: GamesTypes.FETCH_START,
	payload: {
		offset
	}
});

const fetchComplete = (offset, data) => ({
	type: GamesTypes.FETCH_COMPLETE,
	payload: {
		offset,
		data: data.map(game => ({
			appid: (() => {
				try {
					return parseInt(new RegExp('(\\d+)\\/header').exec(game.header_image)[1]);
				} catch (err) {
					console.error(`Failed to parse appid for ${game.name}`)
				}
			})(),
			...game
		}))
	}
});

const fetchMetaStart = () => ({
	type: GamesTypes.FETCH_META_START
});

const fetchMetaComplete = (meta) => ({
	type: GamesTypes.FETCH_META_COMPLETE,
	payload: {
		stats: meta.stats,
		tags: meta.tags,
		genres: meta.genres
	}
});

export const GamesAction = {
	filter,
	fetchStart,
	fetchComplete,
	fetchMetaStart,
	fetchMetaComplete
};
