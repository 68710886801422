import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { ErrorNotFoundComponent } from '../components/error/error-component';
import { IndexPage } from './index/index-page';
import { GamesPage } from './games/games-page';

export const Navigator = () => (
	<Router>
		<Switch>
			<Route exact path='/' component={IndexPage}/>
			<Route exact path='/games' component={GamesPage}/>
			<Route component={ErrorNotFoundComponent}/>
		</Switch>
	</Router>
);
