import React from "react";
import { connect } from "react-redux";
import { Button, Dropdown, Form, Input, Layout, Menu, Modal, Typography } from "antd";
import { styles } from "./styles";
import { AuthAction } from "../../redux/auth/auth-action";

const { Header } = Layout;
const { Item } = Menu;
const { Title } = Typography;

class Component extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			authModalVisible: false,
			signupMode: false
		};
		this.handleAuthModalOpen = this.handleAuthModalOpen.bind(this);
		this.handleAuthModalClose = this.handleAuthModalClose.bind(this);
		this.handleFormToggle = this.handleFormToggle.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleMenuClick = this.handleMenuClick.bind(this);
		this.handleSignout = this.handleSignout.bind(this);
	}

	render() {
		const formValidation = [
			{
				required: true,
				message: "Required"
			}
		];
		const authModal = (
			<Modal visible={this.state.authModalVisible} footer={null} onCancel={this.handleAuthModalClose}>
				<React.Fragment>
					<Title level={2}>
						{
							(this.state.signupMode ? "Sign Up" : "Login")
						}
					</Title>
					<Form onFinish={this.handleFormSubmit}>
						<Form.Item label="Email" name="email" rules={formValidation}>
							<Input/>
						</Form.Item>
						<Form.Item label="Password" name="password" rules={formValidation}>
							<Input.Password/>
						</Form.Item>
						<p style={styles.error}>
							{
								this.props.AuthReducer.error
							}
						</p>
						<Form.Item>
							<Button onClick={this.handleFormToggle}>
								{
									(this.state.signupMode ? "Login instead" : "Sign up instead")
								}
							</Button>
							<Button style={styles.buttonSubmit} type="primary" htmlType="submit">
								Submit
							</Button>
						</Form.Item>
					</Form>
				</React.Fragment>
			</Modal>
		);
		const avatarDropdown = (
			<Dropdown
				overlay={(
					<Menu onClick={this.handleMenuClick}>
						<Item key="signout">
							Sign Out
						</Item>
					</Menu>
				)}
				placement='bottomRight'
			>
				<Button>
					logged in
				</Button>
			</Dropdown>
		);
		const loginDropdown = (
			<React.Fragment>
				<Button onClick={this.handleAuthModalOpen}>
					sign in
				</Button>
				{authModal}
			</React.Fragment>
		);
		return (
			<React.Fragment>
				<Header style={styles.container}>
					<div style={styles.header}>
						{
							(this.props.AuthReducer.userid === null ? loginDropdown : avatarDropdown)
						}
					</div>
				</Header>
			</React.Fragment>
		);
	}

	componentDidMount() {
		this.props.dispatch(AuthAction.status());
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.authModalVisible && this.props.userid) {
			this.handleAuthModalClose();
		}
	}

	handleAuthModalOpen() {
		this.setState({
			authModalVisible: true
		});
	}

	handleAuthModalClose() {
		this.setState({
			authModalVisible: false
		});
	}

	handleFormToggle() {
		this.setState({
			signupMode: !this.state.signupMode
		});
	}

	handleFormSubmit(values) {
		if (this.state.signupMode) {
			this.props.dispatch(AuthAction.signupStart(values.email, values.password));
		} else {
			this.props.dispatch(AuthAction.loginStart(values.email, values.password));
		}
	}

	handleMenuClick(item) {
		const menuHandlers = {
			'signout': this.handleSignout
		};
		menuHandlers[item.key]();
	}

	handleSignout() {
		this.props.dispatch(AuthAction.logout());
	}

}

const Redux = connect(store => ({
	AuthReducer: store.AuthReducer
}))(Component);

export const HeaderComponent = Redux;
