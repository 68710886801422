import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { FavoritesTypes } from "./favorites-types";
import { FavoritesAction } from "./favorites-action";

const fetchStart = function* () {
	const fetchTask = () => new Promise((resolve, reject) => {
		fetch("/api/favorites").then(response => response.json()).then((json) => {
			resolve(json);
		}).catch((err) => {
			reject(err);
		});
	});
	let favorites = yield call(fetchTask);
	if (Object.keys(favorites).length === 0 && favorites.constructor === Object) {
		favorites = [];
	} else {
		favorites = [
			favorites
		];
	}
	yield put(FavoritesAction.fetchComplete(favorites));
};

const set = function* (action) {
	const fetchTask = () => new Promise((resolve, reject) => {
		fetch(`/api/favorites?appid=${action.payload.appid}`, {
			method: "POST"
		}).then(response => response.json()).then((json) => {
			resolve(json);
		}).catch((err) => {
			reject(err);
		});
	});
	yield call(fetchTask);
	yield put(FavoritesAction.fetchStart());
};

const unset = function* (action) {
	const fetchTask = () => new Promise((resolve, reject) => {
		fetch(`/api/favorites?appid=${action.payload.appid}`, {
			method: "DELETE"
		}).then(response => response.json()).then((json) => {
			resolve(json);
		}).catch((err) => {
			reject(err);
		});
	});
	yield call(fetchTask);
	yield put(FavoritesAction.fetchStart());
};

export const FavoritesSaga = function* () {
	yield takeLatest(FavoritesTypes.FETCH_START, fetchStart);
	yield takeEvery(FavoritesTypes.SET, set);
	yield takeEvery(FavoritesTypes.UNSET, unset);
};
