import { call, put, takeLatest } from "redux-saga/effects";
import { RecommendationsTypes } from "./recommendations-types";
import { RecommendationsAction } from "./recommendations-action";

const fetchStart = function* (action) {
	const fetchTask = () => new Promise((resolve, reject) => {
		fetch("/api/nnrecommend").then(response => response.json()).then((json) => {
			resolve(json);
		}).catch((err) => {
			reject(err);
		});
	});
	const recommendations = yield call(fetchTask);
	yield put(RecommendationsAction.fetchComplete(recommendations));
};

export const RecommendationsSaga = function* () {
	yield takeLatest(RecommendationsTypes.FETCH_START, fetchStart);
};
